<template>
  <main class="container padding">
    <div v-if="reportType.type === 'etiquetaRelatorio'">
      <b-form-group align-v="center">
        <b-form-radio-group
          v-model="tipoVisualizacao"
          class="radio-group-class"
          button-variant="primary-group"
          size="lg"
          :options="[
            { text: 'Relatório', value: 'relatorio' },
            { text: 'Etiqueta', value: 'etiqueta' },
          ]"
          buttons
        ></b-form-radio-group>
      </b-form-group>
    </div>

    <div
      v-if="
        reportType.type === 'relatorio' ||
        reportType.type === 'default' ||
        tipoVisualizacao === 'relatorio'
      "
    >
      <PrinterRelatorio
        buttonName="IMPRIMIR"
        hideSection="section"
        hideContent="content"
      >
        <template #printContent class="w">
          <Report
            :reportProcesso="reportProcesso"
            :reportsMateriais="reportsMateriais"
            :reportType="reportType"
          />
        </template>
      </PrinterRelatorio>
    </div>

    <div
      v-if="reportType.type === 'etiqueta' || tipoVisualizacao === 'etiqueta'"
    >
      <TabNav v-model="tab" :tabs="tabs">
        <div v-if="tab === 'etiqueta'">
          <div
            class="div-arrow-left"
            v-b-tooltip.hover.top="'Material anterior'"
          >
            <div
              class="arrows left-button ripple"
              font-scale="1.9"
              type="button"
              @click="changeEtiqueta(-1)"
              v-if="selectedEtiquetaIndex >= 1"
            >
              <chevron-left-icon class="chevron-icon" />
            </div>
          </div>
          <div
            class="div-arrow-right"
            v-b-tooltip.hover.top="'Próximo material'"
          >
            <div
              class="arrows right-button ripple"
              font-scale="1.9"
              type="button"
              @click="changeEtiqueta(1)"
              v-if="selectedEtiquetaIndex < reportsMateriais.length - 1"
            >
              <chevron-right-icon class="chevron-icon" />
            </div>
          </div>

          <PrinterZebra
            v-for="(reportMaterial, indexReportMaterial) in reportsMateriais"
            :key="`reportMaterial${indexReportMaterial}`"
            :bulkZpl="
              reportsMateriais.map((r) => ({
                string: r.zpl || r.material.stringZebra,
                quantidade: r.quantidade,
                isGeneric: Boolean(r.material.tipoMaterialGenerico)
              }))
            "
            v-show="selectedEtiquetaIndex === indexReportMaterial"
          >
            <template #printContent class="w">
              <Label
                :reportProcesso="reportProcesso"
                :reportMaterial="reportMaterial"
                :reportType="reportType"
              />
            </template>
          </PrinterZebra>
        </div>

        <div v-else>
          <PrinterRelatorio
            buttonName="IMPRIMIR"
            hideSection="section"
            hideContent="content"
          >
            <template v-slot:printContent="props" class="w">
              <Checklist
                :reportsMateriais="reportsMateriais"
                :isPrinting="props.isPrinting"
              />
            </template>
          </PrinterRelatorio>
        </div>
      </TabNav>
    </div>
  </main>
</template>

<script>
import Report from './Report';
import Label from './Label';
import Checklist from './Checklist';
import PrinterRelatorio from '@/components/Utils/PrinterRelatorio';
import PrinterZebra from '@/components/Utils/PrinterZebra';
import TabNav from '@/components/Navegacao/TabNav';

export default {
  name: 'DynamicReport',
  components: {
    Report,
    Label,
    PrinterRelatorio,
    PrinterZebra,
    TabNav,
    Checklist,
  },

  data() {
    return {
      selectedEtiquetaIndex: this.reportType.firtElement || 0,
      tipoVisualizacao: this.reportType,
      tab: 'etiqueta',
    };
  },

  watch: {
    tipoVisualizacao(val) {
      this.$emit('tipoVisualizacao', val);
    },
  },
  computed: {
    tabs() {
      return this.reportsMateriais.some((report) => report.checklist)
        ? [
          {
            title: 'Etiqueta',
            value: 'etiqueta',
          },
          {
            title: 'Checklist',
            value: 'checklist',
          },
        ]
        : [
          {
            title: 'Etiqueta',
            value: 'etiqueta',
          },
        ];
    },
  },

  props: {
    reportProcesso: {
      type: Object,
      required: true,
    },
    reportsMateriais: {
      type: Array,
      required: true,
    },
    reportType: {
      type: Object,
      required: true,
    },
  },

  methods: {
    changeEtiqueta(step) {
      if (this.selectedEtiquetaIndex + step < 0) return;
      if (this.selectedEtiquetaIndex + step >= this.reportsMateriais.length) {
        return;
      }

      this.selectedEtiquetaIndex += step;
    },
  },
};
</script>

<style scoped>
.div-arrow-left {
  position: absolute;
  top: 50%;
  left: -20%;
  filter: drop-shadow(4px 4px 20px rgba(61, 70, 85, 0.15));
  width: 4rem;
  height: 4rem;
}

.div-arrow-right {
  position: absolute;
  top: 50%;
  right: -20%;
  filter: drop-shadow(4px 4px 20px rgba(61, 70, 85, 0.15));
  width: 4rem;
  height: 4rem;
}

.div-arrow-left .arrows {
  background: #ffffff;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0.8;
  background-position: center;
  transition: background 0.8s;
}

.div-arrow-right .arrows {
  background: #ffffff;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0.8;
  background-position: center;
  transition: background 0.8s;
}

.div-arrow-left .arrows:hover {
  background-color: rgb(230, 230, 230);
  background-position: center;
  transition: background 0.8s;
}

.div-arrow-right .arrows:hover {
  background-color: rgb(230, 230, 230);
  background-position: center;
  transition: background 0.8s;
}

.ripple:active {
  background-color: rgb(128, 128, 128);
  background-size: 100%;
  transition: background 0s;
}

.chevron-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.material-nav {
  list-style: none;
  padding-inline-start: 0;
  width: 100%;
  display: flex;
  border-bottom: 1px solid #cfd1e6;
  margin-bottom: 36px;
}

.material-nav li {
  color: #7e829f;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  cursor: pointer;
  padding: 0 24px 14px 24px;
}

.nav-item-active {
  border-bottom: 3px solid #209f85 !important;
  color: #209f85 !important;
  margin-bottom: -2.5px;
}
</style>
