var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"container padding"},[(_vm.reportType.type === 'etiquetaRelatorio')?_c('div',[_c('b-form-group',{attrs:{"align-v":"center"}},[_c('b-form-radio-group',{staticClass:"radio-group-class",attrs:{"button-variant":"primary-group","size":"lg","options":[
          { text: 'Relatório', value: 'relatorio' },
          { text: 'Etiqueta', value: 'etiqueta' } ],"buttons":""},model:{value:(_vm.tipoVisualizacao),callback:function ($$v) {_vm.tipoVisualizacao=$$v},expression:"tipoVisualizacao"}})],1)],1):_vm._e(),(
      _vm.reportType.type === 'relatorio' ||
      _vm.reportType.type === 'default' ||
      _vm.tipoVisualizacao === 'relatorio'
    )?_c('div',[_c('PrinterRelatorio',{attrs:{"buttonName":"IMPRIMIR","hideSection":"section","hideContent":"content"},scopedSlots:_vm._u([{key:"printContent",fn:function(){return [_c('Report',{attrs:{"reportProcesso":_vm.reportProcesso,"reportsMateriais":_vm.reportsMateriais,"reportType":_vm.reportType}})]},proxy:true}],null,false,1306393170)})],1):_vm._e(),(_vm.reportType.type === 'etiqueta' || _vm.tipoVisualizacao === 'etiqueta')?_c('div',[_c('TabNav',{attrs:{"tabs":_vm.tabs},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[(_vm.tab === 'etiqueta')?_c('div',[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Material anterior'),expression:"'Material anterior'",modifiers:{"hover":true,"top":true}}],staticClass:"div-arrow-left"},[(_vm.selectedEtiquetaIndex >= 1)?_c('div',{staticClass:"arrows left-button ripple",attrs:{"font-scale":"1.9","type":"button"},on:{"click":function($event){return _vm.changeEtiqueta(-1)}}},[_c('chevron-left-icon',{staticClass:"chevron-icon"})],1):_vm._e()]),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Próximo material'),expression:"'Próximo material'",modifiers:{"hover":true,"top":true}}],staticClass:"div-arrow-right"},[(_vm.selectedEtiquetaIndex < _vm.reportsMateriais.length - 1)?_c('div',{staticClass:"arrows right-button ripple",attrs:{"font-scale":"1.9","type":"button"},on:{"click":function($event){return _vm.changeEtiqueta(1)}}},[_c('chevron-right-icon',{staticClass:"chevron-icon"})],1):_vm._e()]),_vm._l((_vm.reportsMateriais),function(reportMaterial,indexReportMaterial){return _c('PrinterZebra',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedEtiquetaIndex === indexReportMaterial),expression:"selectedEtiquetaIndex === indexReportMaterial"}],key:("reportMaterial" + indexReportMaterial),attrs:{"bulkZpl":_vm.reportsMateriais.map(function (r) { return ({
              string: r.zpl || r.material.stringZebra,
              quantidade: r.quantidade,
              isGeneric: Boolean(r.material.tipoMaterialGenerico)
            }); })},scopedSlots:_vm._u([{key:"printContent",fn:function(){return [_c('Label',{attrs:{"reportProcesso":_vm.reportProcesso,"reportMaterial":reportMaterial,"reportType":_vm.reportType}})]},proxy:true}],null,true)})})],2):_c('div',[_c('PrinterRelatorio',{attrs:{"buttonName":"IMPRIMIR","hideSection":"section","hideContent":"content"},scopedSlots:_vm._u([{key:"printContent",fn:function(props){return [_c('Checklist',{attrs:{"reportsMateriais":_vm.reportsMateriais,"isPrinting":props.isPrinting}})]}}],null,false,809173068)})],1)])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }