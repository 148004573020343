<template>
  <div>
    <div>
      <div id="printPageContent">
        <slot name="printContent" />
      </div>
      <br/>
      <div v-if="useNumberLabelToPrint" class="div-many-labels">

        <span style="margin-top: 0.5rem">Quantidade de etiquetas a imprimir: </span>
        <b-form-input
          id="quantidadeEtiq"
          type="number"
          v-model="quantidadeEtiq"
          autocomplete="off"
          class="invision-input"
          name="quantidadeEtiq"
          :min="1"
          :max="quantidadeMax"
          v-validate="{ required: true, min_value: 1, max_value: quantidadeMax }"
          :state="+quantidadeEtiq > 0 && +quantidadeEtiq <= +quantidadeMax"
        ></b-form-input>
      </div>
      <b-row>
        <b-col>
          <div v-if="showCalibrateButton" class="calibrate-button">
            <Button text="Calibrar" @click="calibrate" :loading="printing" />
          </div>
        </b-col>
        <b-col>
          <div class="printer-buttons">
            <Button text="Imprimir" @click="print" :loading="printing" />
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import importScriptsZebra from '@/services/zebra';
import Button from './Button';

export default {
  inject: [
    'parentValidator',
  ],
  components: {
    Button,
  },

  props: {
    showCalibrateButton: {
      type: Boolean,
      default: true,
    },
    zpl: {
      type: String,
      required: false,
    },
    bulkZpl: {
      type: Array,
      required: false,
    },
  },

  data() {
    return {
      printing: false,
      errorZpl: false,
      useNumberLabelToPrint: this.bulkZpl[0].isGeneric && Boolean(this.bulkZpl.length === 1),
      quantidadeEtiq: this.bulkZpl[0].isGeneric && Boolean(this.bulkZpl.length === 1)
        ? this.bulkZpl[0].quantidade : null,
      quantidadeMax: this.bulkZpl[0].quantidade,
    };
  },
  created() {
    this.$validator = this.parentValidator;
  },
  mounted() {
    importScriptsZebra();
  },

  methods: {
    async print() {
      const validations = await this.$validator.validateAll();
      if (!validations) {
        return;
      }
      if (this.errorZpl) {
        this.printWindow();
      } else if (this.zpl || this.bulkZpl) {
        this.sendZPL();
      } else {
        this.printWindow();
      }
    },

    calibrate() {
      this.sendZPL('~JC');
    },

    printWindow() {
      window.scrollTo(0, 0);
      window.print();
    },

    async sendZPL(zpl) {
      try {
        this.printing = true;

        await this.printerJob(zpl);
      } catch (error) {
        await this.handleError(error);
      } finally {
        this.printing = false;
      }
    },

    sendToZebra(device, string) {
      return new Promise((resolve, reject) => {
        device.send(string, resolve, reject);
      });
    },

    getDevices() {
      return new Promise((resolve, reject) => {
        // eslint-disable-next-line no-undef
        BrowserPrint.getLocalDevices(resolve, reject);
      });
    },

    async printerJob(zpl, indexPrinter = 0) {
      const deviceList = await this.getDevices();

      if (!deviceList || !deviceList.printer || !deviceList.printer[0]) {
        throw new Error('Não encontrou nenhuma impressora');
      }
      try {
        if (zpl) {
          this.sendToZebra(deviceList.printer[indexPrinter], zpl);
        } else if (this.zpl) {
          this.sendToZebra(deviceList.printer[indexPrinter], this.zpl);
        } else if (this.bulkZpl) {
          const zpls = this.bulkZpl.reduce((acum, z) => {
            const novosZpl = new Array(this.quantidadeEtiq ? +this.quantidadeEtiq : z.quantidade).fill(z.string);
            return [...acum, ...novosZpl];
          }, []);

          await Promise.all(zpls.map((z) => this.sendToZebra(deviceList.printer[indexPrinter], z)));
        }

        await swal({
          title: 'Sucesso!',
          text: 'Enviado para impressora com sucesso!',
          icon: 'success',
          button: 'OK',
        });
      } catch (error) {
        if (deviceList.printer[indexPrinter + 1]) {
          const result = await swal({
            title: 'Ops!',
            // eslint-disable-next-line max-len
            text: 'A impressora atual não está respondendo, mas existe outra. Deseja imprimir nela?',
            icon: 'warning',
            buttons: { cancel: 'Não', confirm: 'Sim' },
          });

          if (result) {
            return this.printerJob(zpl, indexPrinter + 1);
          }
        }

        throw error;
      }

      return Promise.resolve();
    },

    async handleError() {
      this.errorZpl = true;

      await swal({
        title: 'Erro',
        icon: 'error',
        text: `Ocorreu um erro na comunicação automática com a impressora.
          Favor contactar o suporte.
          Em contingência, realize a impressão de cada etiqueta de formal individual.`,
        button: 'Continuar...',
      });

      this.printWindow();
    },
  },
};
</script>

<style>
  @media print {
    body * {
      visibility: hidden;
    }
    #printPageContent,
    #printPageContent * {
      visibility: visible;
    }
    #printPageContent {
      position: fixed;
      left: 0;
      top: 0;
    }
  }
</style>

<style scoped>
  .modal-printer-button {
    display: flex;
    justify-content: space-between;
  }
  .text-white {
    margin-right: 2rem;
  }
  .printer-buttons {
    align-items: center;
    justify-content: flex-end;
    display: flex;
  }
  .div-many-labels {
    padding: 0 8px 24px 8px;
    color: #35384d;
    display: flex;
    align-content: center;
  }

  .div-many-labels #quantidadeEtiq {
    width: 6rem;
    text-align: center;
    margin-left: 0.5rem;
  }
  .div-many-labels h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
  }
  .div-many-labels span {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }
</style>
