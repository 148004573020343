<template>
  <div>
    <b-row v-show="isPrinting" class="my-4" align-v="center" align-h="between">
      <b-col cols="auto">
        <h3>Checklist do material</h3>
      </b-col>
      <b-col cols="auto">
        <img :src="imgLogo()" alt="logo" width="150px" />
      </b-col>
    </b-row>
    <div v-for="report of reportsMateriais" :key="report.material.id">
      <p>
        <strong>{{ report.material.descricao }}</strong>
        {{ report.material.cor ? `- ${report.material.cor}` : '' }}
        <span class="text-muted"> (Id: {{ report.material.id }})</span>
      </p>
      <table class="table mt-4 mb-4">
        <thead>
          <tr>
            <th style="font-size: 14px">#</th>
            <th style="font-size: 14px">Descrição</th>
            <th style="font-size: 14px">Quantidade</th>
            <th style="font-size: 14px">Lidos</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in report.checklist.itens"
            :key="item.id_item"
          >
            <th>{{ index + 1 }}</th>
            <td>{{ item.descricao || '-' }}</td>
            <td>{{ item.qtd || '-' }}</td>
            <td>{{ item.lidos }}</td>
          </tr>
          <tr>
            <td><strong>Total</strong></td>
            <td></td>
            <td>{{ report.checklist.total }}</td>
            <td>{{ report.checklist.totalLidos }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script >
export default {
  props: {
    reportsMateriais: {
      type: Array,
      required: true,
    },
    isPrinting: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    // TODO Buscar logo do back end e armazenar em cache
    imgLogo() {
      try {
        // eslint-disable-next-line global-require, import/no-dynamic-require
        return require(`../../../../projects/${process.env.VUE_APP_PROJECT}/front-end/assets/img/logo.png`);
      } catch (e) {
        // eslint-disable-next-line global-require
        const logo = require('@/assets/img/logo.png');
        return logo;
      }
    },
  },
};
</script>
