<template>
  <div class="etiqueta pt-0" id="printSection">
    <span>Total de etiquetas: {{ reportMaterial.quantidade }} etiquetas</span>
    <br />
    <div class="cabecalho">
      <img :src="imgLogo()" alt="logo" width="150px" />
      <qrcode-vue
        :value="reportMaterial.code"
        level="H"
        class="qr-code"
        renderAs="svg"
      />
    </div>
    <br />
    <br />
    <b-row><b>Processo: </b> {{ reportType.processo }}</b-row>
    <b-row
      class="pt-0 pb-0"
      v-for="(value, field) in reportMaterial.fields"
      :key="field"
    >
      <span v-if="field != 'Quantidade'">
        <b>{{ field }}:</b> {{ value }}
      </span>
    </b-row>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue';

export default {
  name: 'Report',
  components: {
    QrcodeVue,
  },

  data() {
    return {};
  },

  props: {
    reportMaterial: {
      type: Object,
      required: true,
    },
    reportType: {
      type: Object,
      required: true,
    },
  },

  methods: {
    // TODO Buscar logo do back end e armazenar em cache
    imgLogo() {
      try {
        // eslint-disable-next-line global-require, import/no-dynamic-require
        return require(`../../../../projects/${process.env.VUE_APP_PROJECT}/front-end/assets/img/logo.png`);
      } catch (e) {
        // eslint-disable-next-line global-require
        const logo = require('@/assets/img/logo.png');
        return logo;
      }
    },
  },
};
</script>

<style scoped>
  .cabecalho {
    display: flex;
    justify-content: space-between;
  }
</style>
